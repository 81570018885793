
import Vue from 'vue';
import utils from '../../util';
import { AdvertiserDataApi, AdvertiserFilter, FilterTypes } from '../../store/modules/customer/types';

export default Vue.extend({
  name: 'linearAdminAdvertiserList',
  props: ['lastCurrentPage'],
  components: {
    Advertiser: () => import('./advertiser.vue'),
  },
  created(): void {
    this.getAdvertisers(true);
  },
  data(): {
    search: string;
    sortDesc: Array<boolean>;
    advertisers: Array<AdvertiserDataApi>;
    currentPage: number;
    pageSize: string | null;
    totalPages: number | null;
    totalRecords: number | null;
    selectedStations: Array<string>;
    selectedFeeds: Array<string>;
    selectedPartners: Object | null;
    itemsPer: Array<number>;
    selectedItemsPer: number;
    stationFilter: AdvertiserFilter;
    partnerFilter: AdvertiserFilter;
    bestMatch: boolean;
    sortActive: boolean;
    searchTimer: Function | null;
  } {
    return {
      search: '',
      sortDesc: [false],
      advertisers: [],
      currentPage: 1,
      pageSize: null,
      totalPages: null,
      totalRecords: null,
      selectedStations: [],
      selectedFeeds: [],
      selectedPartners: null,
      itemsPer: [10, 25, 50, 75, 100],
      selectedItemsPer: 100,
      stationFilter: { DisplayName: null, Elements: [], Key: null },
      partnerFilter: { DisplayName: null, Elements: [], Key: null },
      bestMatch: false,
      sortActive: false,
      searchTimer: null,
    };
  },
  watch: {
    '$store.state.linearAdmin.linearAdminClients': {
      handler(): void {
        this.updateList();
      },
    },
    search: {
      handler(): void {
        clearTimeout(this.searchTimer);
        if (this.search.length >= 3 || this.search.length === 0) {
          this.searchTimer = setTimeout(() => {
            this.changeParams();
          }, 500);
        }
      },
    },
    sortDesc: {
      handler(): void {
        this.changeParams(true);
      },
      deep: true,
    },
  },
  methods: {
    async getAdvertisers(firstLoad: boolean): Promise<void> {
      const pg = firstLoad && this.lastCurrentPage ? this.lastCurrentPage : this.currentPage;
      await this.$store.dispatch('linearAdmin/getLinearAdminClientsSearch', {
        name: this.search,
        limit: this.selectedItemsPer,
        offset: pg === 1 ? 0 : (pg - 1) * this.selectedItemsPer,
        partners: this.selectedPartners?.value ? this.selectedPartners.value : '',
        stations: this.selectedStations.length ? this.selectedStations.map(s => s.value).join() : '',
        sortby: this.sortActive ? this.sortBy : '',
        sortdesc: this.sortDesc[0],
      });
      if (Array.isArray(this.clientList)) {
        this.advertisers = this.clientList;
      }
    },
    openAction(action: string): void {
      this.$emit('open-action', action);
    },
    hasRight(roles: Array<string>): boolean {
      if (!this.$store.state.customer.user) return false;
      return utils.hasRight(this.$store.getters.user, roles);
    },
    changePage(): void {
      this.$emit('update-page', this.currentPage);
      this.advertisers = [];
      this.getAdvertisers();
    },
    changeParams(sort): void {
      if (sort && this.sortDesc.length === 0) this.sortDesc = [false];
      this.currentPage = 1;
      this.totalPages = null;
      this.advertisers = [];
      this.getAdvertisers();
    },
    formatElement(data): { value: string; text: string }[] {
      if (!data) return [];
      const items = Array.isArray(data) ? data : [data];
      return items.map(d => {
        return {
          value: d.Value_Agency_Id || d.Value,
          text: `${d.Value_Agency_Name || d.Value} (${d.Count})`,
        };
      });
    },
    updateList(): void {
      this.totalPages = Math.ceil(
        parseInt(this.$store.state.linearAdmin.linearAdminClients.total, 10) / this.selectedItemsPer,
      );
      this.totalRecords = parseInt(this.$store.state.linearAdmin.linearAdminClients.total, 10);
      this.stationFilter = this.filterList(
        this.$store.state.linearAdmin.linearAdminClients.facets,
        FilterTypes.AdStations,
      );
      this.partnerFilter = this.filterList(
        this.$store.state.linearAdmin.linearAdminClients.facets,
        FilterTypes.AgencyPartner,
      );
      if (this.$store.state.linearAdmin.linearAdminClients?.sort?.by.toLowerCase() === 'score') {
        this.bestMatch = true;
      }
    },
    filterList(arr, key): Array<AdvertiserFilter> {
      if (!arr) {
        return [];
      }
      const filters = Array.isArray(arr) ? arr : [arr];
      return filters.find(filter => filter.Key === key);
    },
    sortList(): void {
      this.sortActive = true;
      this.changeParams();
    },
  },
  computed: {
    headers(): Record<string, string | boolean>[] {
      let arr = [
        { text: 'Advertisers', value: 'name', sortable: !this.$vuetify.breakpoint.xsOnly },
        { text: 'Partner', value: 'agency_id', sortable: false },
        { text: 'Station', value: 'stations', sortable: false },
        { text: 'Website', value: 'url', sortable: false },
        { text: 'Status', value: 'Status', sortable: false },
        { text: 'Created', value: 'created_at', sortable: false },
        { text: 'Last campaign', value: 'last_campaign_date', sortable: false },
        { text: 'GA status', value: 'gaVerified', sortable: false },
        // { text: 'Next Action', value: 'nextAction', sortable: false },
        { text: '', value: '', sortable: false },
      ];

      if (!this.$vuetify.breakpoint.lgAndUp) {
        // remove some columns from smaller screens
        const hiddenColumns = ['stations'];
        arr = arr.filter((header: { text: string; value: string }) => {
          if (!hiddenColumns.includes(header.value)) return header;
        });
      }
      if (!this.superUser) {
        // Show only for super user
        const hiddenColumns = ['agency_id'];
        arr = arr.filter((header: { text: string; value: string }) => {
          if (!hiddenColumns.includes(header.value)) return header;
        });
      }
      return arr;
    },
    clientList(): AdvertiserDataApi[] | null {
      if (!this.$store.state.linearAdmin?.linearAdminClients?.advertisers) {
        return null;
      }
      return this.$store.state.linearAdmin.linearAdminClients.advertisers;
    },
    filterMessage(): string {
      return this.$store.state.linearAdmin.loading ? 'Loading filter' : 'N/A';
    },
    sortBy(): string {
      return this.bestMatch ? 'Score' : 'name';
    },
    superUser(): boolean {
      return utils.hasRight(this.$store.getters.user, ['SU']);
    },
  },
});
